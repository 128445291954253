{
  "name": "constructor",
  "version": "1.0.0",
  "author": "Mathieu CAROFF <mathieu.caroff@free.fr>",
  "packageManager": "yarn@4.3.1",
  "repository": {
    "type": "git",
    "url": "https://github.com/mathieucaroff/constructor"
  },
  "license": "CC0-1.0",
  "scripts": {
    "check": "tsc --noEmit",
    "checkwatch": "tsc --noEmit --watch",
    "build": "parcel build src/index.html",
    "mcabuild": "parcel build src/index.html --public-url constructor.d",
    "dev": "parcel src/index.html",
    "test": "mocha src/test.ts"
  },
  "devDependencies": {
    "@types/assert": "^1.5.10",
    "@types/mocha": "^10.0.7",
    "@types/react": "^17.0.39",
    "@types/react-dom": "^17.0.11",
    "mocha": "^10.7.0",
    "parcel": "^2.3.2",
    "prettier": "^2.5.1",
    "process": "^0.11.10",
    "tsx": "^4.16.2",
    "typescript": "^4.5.5"
  },
  "dependencies": {
    "@picocss/pico": "^1.5.6",
    "react": "^17.0.2",
    "react-dom": "^17.0.2"
  }
}
